import styles from './AnnouncementListSkeleton.module.css';
import classNames from 'classnames/bind';
import { Spin } from 'antd';

const cx = classNames.bind(styles);

interface IAnnouncementListSkeletonProps {
  dataLength?: number;
}

function AnnouncementListSkeleton({
  dataLength = 10,
}: IAnnouncementListSkeletonProps) {
  return (
    <Spin tip="불러오는중..">
      <ul className={cx('wrapper')}>
        {new Array(dataLength).fill(0).map((_, key) => (
          <li key={key} className={cx('list-item')} />
        ))}
      </ul>
    </Spin>
  );
}

export default AnnouncementListSkeleton;
