import { AxiosError, AxiosResponse } from 'axios';

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IUseAPIArgs } from 'types/common';

const useAPI = <TData = unknown>(
  api: (arg?: any) => Promise<AxiosResponse<IUseAPIArgs<TData>, any>>
) => {
  const router = useRouter();
  const { query, isReady, pathname, locale } = router;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<IUseAPIArgs<TData> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  const handlePagination = async (nextPage: number) => {
    window.scrollTo(0, 0);
    router.query.page = `${nextPage}`;
    router.push(
      {
        pathname,
        query: { ...query },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const handleSearch = async (search: string) => {
    router.query.search = search;
    router.query.page = '1';
    router.push(
      {
        pathname,
        query: { ...query },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const apiCall = async (args?: unknown) => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<IUseAPIArgs<TData>> = await api(args);
      setData(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        setIsError(true);
        setError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isReady) return;
    apiCall(query);
  }, [isReady, query.page, query.search, locale]);

  return {
    data,
    isLoading,
    isError,
    error,
    refetch: apiCall,
    handlePagination,
    handleSearch,
  };
};

export default useAPI;
